import {
  useEnvironment,
  useFedopsLogger,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { classes, st } from './Coupon.st.css';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { CouponDataHooks } from './dataHooks';
import { useFormActions } from '../../Hooks/useFormActions';
import TagIcon from 'wix-ui-icons-common/on-stage/TagSmall';
import { TextField } from 'wix-ui-tpa/TextField';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { ReservedPaymentOptionIds } from '../../../../types/types';
import { ServicePayment } from '@wix/bookings-uou-types';
import { isFixedPrice } from '../../../../utils/payment/payment';
import { Divider } from 'wix-ui-tpa/Divider';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { CouponsErrorType, FormErrors } from '../../../../types/errors';
import { CouponInfo } from '../../../../types/coupons';
import { getErrorByType } from '../../../../utils/errors/errors';
import { FormStatus } from '../../../../types/form-state';

export const ENTER = 'enter';
export interface CouponProps {
  couponInfo: CouponInfo;
  selectedPaymentOptionId: string;
  servicePayment: ServicePayment;
  errors: FormErrors[];
  status: FormStatus;
  email?: string;
}

const Coupon: React.FC<CouponProps> = ({
  couponInfo,
  selectedPaymentOptionId,
  servicePayment,
  errors,
  status,
}) => {
  const { isMobile } = useEnvironment();
  const fedopsLogger = useFedopsLogger();
  const { t } = useTranslation();
  const { setCoupon, removeCoupon, onToggleCoupon } = useFormActions();
  const {
    areCouponsAvailable,
    isCouponInputDisplayed,
    appliedCoupon,
  } = couponInfo;
  const couponRef = useRef<HTMLInputElement>();
  const [couponCode, setCouponCode] = useState<Maybe<string>>(
    appliedCoupon?.couponCode,
  );

  const applyCoupon = (code: Maybe<string>) => {
    fedopsLogger.interactionStarted('submit-coupon-interaction');
    setCoupon(code);
  };

  const couponError = getErrorByType({
    errorType: CouponsErrorType,
    errors,
  });

  useEffect(() => {
    if (couponCode === '') {
      removeCoupon();
    }
  }, [couponCode, removeCoupon]);

  useEffect(() => {
    const isValidCouponError =
      couponError && couponError !== CouponsErrorType.GENERAL_SERVER_ERROR;
    if (appliedCoupon?.couponCode || isValidCouponError) {
      fedopsLogger.interactionEnded('submit-coupon-interaction');
    }
  }, [appliedCoupon, fedopsLogger, couponError]);

  const isCouponLoading = status === FormStatus.PROCESSING_PAYMENT_DETAILS;
  const shouldShowCoupon =
    areCouponsAvailable &&
    isFixedPrice(servicePayment) &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;

  const clearCoupon = () => setCouponCode('');

  const showErrorToolTip =
    couponError === CouponsErrorType.COUPON_DOES_NOT_EXIST;

  const getNotificationMessage = useMemo<string>(() => {
    switch (couponError) {
      case CouponsErrorType.COUPON_LIMIT_PER_CUSTOMER_EXCEEDED:
      case CouponsErrorType.COUPON_USAGE_EXCEEDED:
        return t('app.payment.promo-code.errors.already-used-coupon-code', {
          couponCode,
        });
      case CouponsErrorType.COUPON_SERVICE_UNAVAILABLE:
        return t('app.payment.promo-code.no-longer-available.aria-label', {
          coupon: couponCode,
        });
      case CouponsErrorType.NOT_VALID_FOR_SELECTED_SERVICE:
        return t('app.payment.promo-code.invalid-products.aria-label', {
          coupon: couponCode,
        });
      case CouponsErrorType.COUPON_HAS_EXPIRED:
        return t('app.payment.promo-code.expired.aria-label', {
          coupon: couponCode,
        });
      default:
        return '';
    }
  }, [couponError]);

  const couponAriaLabel = () => {
    if (couponInfo.appliedCoupon?.couponDiscount) {
      return t('app.payment.promo-code.succeeded.aria-label');
    }
  };

  useEffect(() => {
    if (couponError) {
      couponRef.current?.focus();
      removeCoupon(false);
    }
  }, [couponError]);

  return shouldShowCoupon ? (
    <div
      className={st(classes.root, { isMobile })}
      role="group"
      aria-label="coupon"
      data-hook={CouponDataHooks.WRAPPER}
    >
      <Divider
        className={classes.divider}
        data-hook={CouponDataHooks.DIVIDER}
      />
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.secondary}
        className={classes.addCouponButton}
        data-hook={CouponDataHooks.COUPON_TEXT_BUTTON}
        onClick={() => onToggleCoupon()}
        aria-expanded={isCouponInputDisplayed}
        prefixIcon={<TagIcon aria-hidden />}
      >
        {t('app.payment.coupon.add-a-promo-code.text')}
      </TextButton>
      {isCouponInputDisplayed ? (
        <>
          <TextField
            data-hook={CouponDataHooks.COUPON_INPUT}
            className={classes.couponTextInput}
            success={!!appliedCoupon}
            disabled={!!appliedCoupon}
            successIcon={!!appliedCoupon}
            value={couponCode}
            error={showErrorToolTip && !isCouponLoading}
            errorMessage={t(
              'app.payment.promo-code.errors.invalid-coupon-code',
            )}
            onChange={(e) => setCouponCode(e.currentTarget.value)}
            onKeyDown={(e) =>
              e.key.toLowerCase() === ENTER
                ? applyCoupon(e.currentTarget.value)
                : null
            }
            inputRef={(ref) => (couponRef.current = ref)}
          />
          {appliedCoupon ? (
            <Button
              size={SIZE.tiny}
              priority={PRIORITY.basicSecondary}
              className={`${classes.couponCta} ${classes.removeCouponCTA}`}
              data-hook={CouponDataHooks.REMOVE_BUTTON}
              onClick={() => clearCoupon()}
              upgrade
            >
              {t('app.payment.promo-code.remove.text')}
            </Button>
          ) : (
            <Button
              size={SIZE.tiny}
              priority={PRIORITY.basic}
              className={`${classes.couponCta} ${classes.applyCouponCTA}`}
              data-hook={CouponDataHooks.ADD_BUTTON}
              onClick={() => applyCoupon(couponCode)}
              disabled={isCouponLoading}
              upgrade
            >
              {t('app.payment.promo-code.apply.text')}
            </Button>
          )}
          <SectionMessage
            text={getNotificationMessage}
            type={SectionMessageType.Error}
          />
          <div
            className={classes.a11yTokens}
            role="alert"
            data-hook={CouponDataHooks.ACCESSIBILITY_TAG}
          >
            {couponAriaLabel()}
          </div>
        </>
      ) : null}
    </div>
  ) : null;
};

export default Coupon;
