import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
  WidgetProps,
  useErrorBoundary,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { classes, st } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef, SubmissionResponse } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { UserSelection } from './UserSelection/UserSelection';
import { FormState } from '../../../utils/state/initialStateFactory';
import BookingDetails from './BookingDetails/BookingDetails';
import { Header } from './Header/Header';
import BookButton from './BookButton/BookButton';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { EditorContextProvider } from '../Hooks/useEditorContext';
import { EmptyStateErrorType, GenericErrorType } from '../../../types/errors';
import Toast from './Toast/Toast';
import { PaymentSelection } from './PaymentSelection/PaymentSelection';
import BackButton from './BackButton/BackButton';
import { getPaymentOptions } from '../../../utils/payment/payment';
import { WidgetDataHooks } from './dataHooks';
import Coupon from './Coupon/Coupon';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { PaymentOption } from '../../../types/types';
import { getErrorByType, hasErrorOfType } from '../../../utils/errors/errors';
import Dialog from './Dialog/Dialog';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../../../utils/mappers/form-submission.mapper';
import { FormStatus } from '../../../types/form-state';
import { FormComponentContextProvider } from '../Hooks/useFormComponentContext';

export type FormComponentActions = {
  submitForm?: () => SubmissionResponse;
};
export type FormActions = FormControllerActions & FormComponentActions;

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  actions,
  service,
  slotAvailability,
  businessInfo,
  pricingPlanDetails,
  memberships,
  isPricingPlanInstalled,
  isMemberAreaInstalled,
  selectedPaymentOptionId,
  paymentDetails,
  couponInfo,
  memberDetails,
  errors,
  editorContext,
  status,
  overrideDefaultFieldsValues,
  dialog,
  formInputs,
  selectedPaymentType,
  isBookingsOnEcom,
}) => {
  useEffect(() => {
    if (status === FormStatus.INITIALIZING) {
      actions.initializeWidget();
    }
  }, []);

  const formRef = useRef<FormRef>();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { error } = useErrorBoundary();
  const submitForm = () => formRef?.current?.submit();
  const settings = useSettings();
  const slot = slotAvailability?.slot!;
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const { numberOfParticipants } = { ...formInputs };
  const processingStatuses = [
    FormStatus.INITIALIZING,
    FormStatus.PROCESSING_USER_DETAILS,
    FormStatus.SSR,
  ];
  const isProcessing = processingStatuses.includes(status);

  const shouldShowEmptyStatePage = () =>
    isProcessing ||
    hasErrorOfType({ errorType: EmptyStateErrorType, errors }) ||
    error;

  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );

  const paymentOptions: PaymentOption[] = useMemo(
    () =>
      getPaymentOptions({
        servicePayment: service?.payment,
        pricingPlanDetails,
        memberships,
        isPricingPlanInstalled,
        dateAndTimeFormatter,
        numberOfParticipants,
        dateRegionalSettingsLocale,
        t,
        settings,
      }),
    [
      service?.payment,
      memberships,
      pricingPlanDetails,
      dateAndTimeFormatter,
      isPricingPlanInstalled,
      numberOfParticipants,
      dateRegionalSettingsLocale,
      t,
      settings,
    ],
  );

  const selectedPaymentOption = paymentOptions.find(
    (paymentOption) => paymentOption.id === selectedPaymentOptionId,
  )!;

  if (shouldShowEmptyStatePage()) {
    return <EmptyStatePage isProcessing={isProcessing} />;
  }

  const toastError = getErrorByType({
    errors,
    errorType: GenericErrorType,
  });

  const showLoader = status === FormStatus.PROCESSING_BOOK_REQUEST;

  const canBookMultipleParticipants = !!getFieldFromSchema(
    service.formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  );

  return (
    <EditorContextProvider value={editorContext} key="form-main-widget">
      <FormActionsProvider value={{ ...actions, submitForm }}>
        <FormComponentContextProvider value={{ isBookingsOnEcom }}>
          <div
            className={st(classes.root, { isMobile, isProcessing: showLoader })}
            data-hook={WidgetDataHooks.MAIN_CONTAINER}
          >
            <div className={classes.wrapper}>
              {showLoader ? (
                <div
                  className={st(classes.blanket)}
                  data-hook={WidgetDataHooks.BLANKET}
                />
              ) : null}
              {toastError ? (
                <Toast
                  toastError={toastError}
                  numberOfParticipants={numberOfParticipants}
                />
              ) : null}
              <BackButton />
              <div className={classes.body}>
                <div className={classes.formWrapper}>
                  <Header {...service.formHeader} />
                  {isMemberAreaInstalled && (
                    <UserSelection memberDetails={memberDetails} />
                  )}
                  <FormInputs
                    formSchema={service.formSchema}
                    formRef={formRef}
                    memberDetails={memberDetails}
                    status={status}
                    overrideDefaultFieldsValues={overrideDefaultFieldsValues}
                  />
                  <PaymentSelection
                    paymentOptions={paymentOptions}
                    selectedPaymentOptionId={selectedPaymentOptionId}
                    numberOfParticipants={numberOfParticipants}
                    dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                    selectedPaymentType={selectedPaymentType}
                    paymentTypes={service?.paymentTypes}
                  />
                </div>
                <div className={classes.sidebar}>
                  <div className={classes.floatingContainer}>
                    <BookingDetails
                      service={service}
                      slot={slot}
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                    />
                    <Coupon
                      couponInfo={couponInfo}
                      servicePayment={service.payment}
                      selectedPaymentOptionId={selectedPaymentOptionId}
                      errors={errors}
                      status={status}
                    />
                    <PaymentSummary
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      servicePayment={paymentDetails}
                      selectedPaymentOption={selectedPaymentOption}
                      numberOfParticipants={numberOfParticipants}
                      showPricePerParticipant={canBookMultipleParticipants}
                      appliedCoupon={couponInfo.appliedCoupon}
                      status={status}
                    />
                    <BookButton
                      isPendingApprovalFlow={service.isPendingApprovalFlow}
                      actionLabels={service.actionLabels!}
                      paymentTypes={service.paymentTypes}
                      selectedPaymentOption={selectedPaymentOption}
                      status={status}
                      errors={errors}
                      paymentDetails={paymentDetails}
                      selectedPaymentType={selectedPaymentType}
                    />
                  </div>
                </div>
              </div>
              {dialog ? <Dialog {...dialog.props} /> : null}
            </div>
          </div>
        </FormComponentContextProvider>
      </FormActionsProvider>
    </EditorContextProvider>
  );
};

export default Widget;
